import { mdiChevronDoubleDown, mdiWhatsapp } from '@mdi/js';
import Icon from '@mdi/react';
import bg from '../../assets/bg-topo.jpg';
import './style.css';

function TopBg(){

    function handleOpenChat(){
        if(window.innerWidth <= 768){
            window.open('https://whatsapp.faleconosco.chat/c86de5f10', '_blank')
        } else { 
            window.open('https://whatsapp.faleconosco.chat/c86de5f10', '_blank')
        }
    }

    return(
        <section id='top' style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: '75vh',
            position: "relative",
            padding: '0px'
        }}>
            <div className="bg-black">
                <div className="bg-title">
                    <h1>BONÉS</h1>
                    <h1>PERSONALIZADOS</h1>
                    <p>Nossos produtos são cuidadosamente desenvolvidos para refletir a identidade da sua empresa, garantindo um produto único e memorável. Com materiais premium e um processo de fabricação minucioso, priorizamos a excelência em cada detalhe. Entre em contato com nossa equipe de vendas pelo Whatsapp <a href="https://api.whatsapp.com/send?phone=5547997496900&text=Ol%C3%A1%20vim%20atrav%C3%A9s%20do%20site%2C%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es!" target="_blank">(47) 99749-6900</a> e solicite seu orçamento.</p>
                    <a className='wpp-action' onClick={handleOpenChat}>Fale Conosco<Icon path={mdiWhatsapp} size={1} /></a>
                </div>
            </div>
            <div className="roll-down">
                <a href="#" onClick={(e) => {
                    e.preventDefault()
                    window.scrollTo(0, 900)
                }}><Icon path={mdiChevronDoubleDown}/></a>
            </div>
        </section>
    )
}

export default TopBg;