import whatsapp from '../../assets/whatsapp-33.png';
import './whatsapp.css';

export default function WhatsappButton(){

    function handleOpenChat(){
        if(window.innerWidth <= 768){
            window.open('https://whatsapp.faleconosco.chat/c86de5f10', '_blank')
        } else { 
            window.open('https://whatsapp.faleconosco.chat/c86de5f10', '_blank')
        }
    }

    return(
        <div className="wpp-button" onClick={handleOpenChat}>
            <img src={whatsapp} alt="Whatsapp"/>
        </div>
    )
}